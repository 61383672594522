// const grid = [
//   { i: '1', x: 0, y: 0, w: 1, h: 1 },
//   { i: '2', x: 1, y: 0, w: 1, h: 1 },
//   { i: '3', x: 2, y: 0, w: 1, h: 1 },
//   { i: '4', x: 3, y: 0, w: 1, h: 1 },
//   { i: '5', x: 4, y: 0, w: 1, h: 1 },
//   { i: '6', x: 5, y: 0, w: 1, h: 1 },
//   { i: '7', x: 6, y: 0, w: 1, h: 1 },
//   { i: '8', x: 7, y: 0, w: 1, h: 1 },
//   { i: '9', x: 8, y: 0, w: 1, h: 1 },
//   { i: '10', x: 9, y: 0, w: 1, h: 1 },
//   { i: '11', x: 10, y: 0, w: 1, h: 1 },
//   { i: '12', x: 11, y: 0, w: 1, h: 1 },
//   { i: '13', x: 12, y: 0, w: 1, h: 1 },
//   { i: '14', x: 13, y: 0, w: 1, h: 1 },
//   { i: '15', x: 0, y: 0, w: 1, h: 1 },
//   { i: '16', x: 1, y: 0, w: 1, h: 1 },
//   { i: '17', x: 2, y: 0, w: 1, h: 1 },
//   { i: '18', x: 3, y: 0, w: 1, h: 1 },
//   { i: '19', x: 4, y: 0, w: 1, h: 1 },
//   { i: '20', x: 5, y: 0, w: 1, h: 1 },
//   { i: '21', x: 6, y: 0, w: 1, h: 1 },
//   { i: '22', x: 7, y: 0, w: 1, h: 1 },
//   { i: '23', x: 8, y: 0, w: 1, h: 1 },
//   { i: '24', x: 9, y: 0, w: 1, h: 1 },
//   { i: '25', x: 10, y: 0, w: 1, h: 1 },
//   { i: '26', x: 11, y: 0, w: 1, h: 1 },
//   { i: '27', x: 12, y: 0, w: 1, h: 1 },
//   { i: '28', x: 13, y: 0, w: 1, h: 1 },
//   { i: '29', x: 0, y: 0, w: 1, h: 1 },
//   { i: '30', x: 1, y: 0, w: 1, h: 1 },
//   { i: '31', x: 2, y: 0, w: 1, h: 1 },
//   { i: '32', x: 3, y: 0, w: 1, h: 1 },
//   { i: '33', x: 4, y: 0, w: 1, h: 1 },
//   { i: '34', x: 5, y: 0, w: 1, h: 1 },
//   { i: '35', x: 6, y: 0, w: 1, h: 1 },
//   { i: '36', x: 7, y: 0, w: 1, h: 1 },
//   { i: '37', x: 8, y: 0, w: 1, h: 1 },
//   { i: '38', x: 9, y: 0, w: 1, h: 1 },
//   { i: '39', x: 10, y: 0, w: 1, h: 1 },
//   { i: '40', x: 11, y: 0, w: 1, h: 1 },
//   { i: '41', x: 12, y: 0, w: 1, h: 1 },
//   { i: '42', x: 13, y: 0, w: 1, h: 1 },
//   { i: '43', x: 0, y: 0, w: 1, h: 1 },
//   { i: '44', x: 1, y: 0, w: 1, h: 1 },
//   { i: '45', x: 2, y: 0, w: 1, h: 1 },
//   { i: '46', x: 3, y: 0, w: 1, h: 1 },
//   { i: '47', x: 4, y: 0, w: 1, h: 1 },
//   { i: '48', x: 5, y: 0, w: 1, h: 1 },
//   { i: '49', x: 6, y: 0, w: 1, h: 1 },
//   { i: '50', x: 7, y: 0, w: 1, h: 1 },
//   { i: '51', x: 8, y: 0, w: 1, h: 1 },
//   { i: '52', x: 9, y: 0, w: 1, h: 1 },
//   { i: '53', x: 10, y: 0, w: 1, h: 1 },
//   { i: '54', x: 11, y: 0, w: 1, h: 1 },
//   { i: '55', x: 12, y: 0, w: 1, h: 1 },
//   { i: '56', x: 13, y: 0, w: 1, h: 1 },
//   { i: '57', x: 0, y: 0, w: 1, h: 1 },
//   { i: '58', x: 1, y: 0, w: 1, h: 1 },
//   { i: '59', x: 2, y: 0, w: 1, h: 1 },
//   { i: '60', x: 3, y: 0, w: 1, h: 1 },
//   { i: '61', x: 4, y: 0, w: 1, h: 1 },
//   { i: '62', x: 5, y: 0, w: 1, h: 1 },
//   { i: '63', x: 6, y: 0, w: 1, h: 1 },
//   { i: '64', x: 7, y: 0, w: 1, h: 1 },
//   { i: '65', x: 8, y: 0, w: 1, h: 1 },
//   { i: '66', x: 9, y: 0, w: 1, h: 1 },
//   { i: '67', x: 10, y: 0, w: 1, h: 1 },
//   { i: '68', x: 11, y: 0, w: 1, h: 1 },
//   { i: '69', x: 12, y: 0, w: 1, h: 1 },
//   { i: '70', x: 13, y: 0, w: 1, h: 1 },
// ];

const yellow = [
    { i: '1', x: 0, y: 0, w: 1, h: 1 },
    { i: '2', x: 1, y: 1, w: 1, h: 2 },
    { i: '3', x: 2, y: 0, w: 1, h: 1 },
    { i: '4', x: 3, y: 0, w: 1, h: 1 },
    { i: '5', x: 4, y: 0, w: 1, h: 1 },
    { i: '6', x: 5, y: 0, w: 1, h: 2 },
    { i: '7', x: 6, y: 0, w: 2, h: 1 },
    // { i: '8', x: 7, y: 0, w: 1, h: 1 },
    { i: '9', x: 8, y: 0, w: 1, h: 1 },
    { i: '10', x: 9, y: 0, w: 1, h: 1 },
    { i: '11', x: 10, y: 0, w: 2, h: 1 },
    // { i: '12', x: 11, y: 0, w: 1, h: 1 },
    { i: '13', x: 12, y: 0, w: 1, h: 1 },
    { i: '14', x: 13, y: 0, w: 1, h: 1 },
    { i: '15', x: 0, y: 0, w: 1, h: 1 },
    { i: '16', x: 1, y: 0, w: 1, h: 1 },
    { i: '17', x: 2, y: 0, w: 1, h: 1 },
    { i: '18', x: 3, y: 0, w: 2, h: 1 },
    // // { i: '19', x: 4, y: 0, w: 1, h: 1 },
    // { i: '20', x: 5, y: 0, w: 1, h: 1 },
    { i: '21', x: 6, y: 0, w: 1, h: 1 },
    { i: '22', x: 7, y: 0, w: 1, h: 1 },
    { i: '23', x: 8, y: 0, w: 1, h: 1 },
    { i: '24', x: 9, y: 0, w: 1, h: 1 },
    { i: '25', x: 10, y: 0, w: 1, h: 1 },
    { i: '26', x: 11, y: 0, w: 1, h: 1 },
    { i: '27', x: 12, y: 0, w: 1, h: 1 },
    { i: '28', x: 13, y: 0, w: 1, h: 1 },
    { i: '29', x: 0, y: 0, w: 1, h: 1 },
    // { i: '30', x: 1, y: 0, w: 1, h: 1 },
    { i: '31', x: 2, y: 0, w: 1, h: 1 },
    { i: '32', x: 3, y: 0, w: 1, h: 1 },
    { i: '33', x: 4, y: 0, w: 1, h: 1 },
    { i: '34', x: 5, y: 0, w: 1, h: 1 },
    { i: '35', x: 6, y: 0, w: 1, h: 1 },
    { i: '36', x: 7, y: 0, w: 1, h: 1 },
    { i: '37', x: 8, y: 0, w: 1, h: 1 },
    { i: '38', x: 9, y: 1, w: 1, h: 1 },
    { i: '39', x: 10, y: 0, w: 1, h: 1 },
    { i: '40', x: 11, y: 0, w: 2, h: 1 },
    // { i: '41', x: 12, y: 0, w: 1, h: 1 },
    { i: '42', x: 13, y: 0, w: 1, h: 1 },
    { i: '43', x: 0, y: 0, w: 1, h: 1 },
    { i: '44', x: 1, y: 1, w: 1, h: 1 },
    { i: '45', x: 2, y: 0, w: 1, h: 1 },
    { i: '46', x: 3, y: 0, w: 1, h: 1 },
    { i: '47', x: 4, y: 0, w: 1, h: 1 },
    { i: '48', x: 5, y: 0, w: 1, h: 1 },
    { i: '49', x: 6, y: 0, w: 1, h: 1 },
    { i: '50', x: 7, y: 1, w: 4, h: 1 },
    // // { i: '51', x: 8, y: 0, w: 1, h: 1 },
    // { i: '52', x: 9, y: 1, w: 1, h: 1 },
    // // { i: '53', x: 10, y: 0, w: 1, h: 1 },
    { i: '54', x: 11, y: 0, w: 1, h: 1 },
    { i: '55', x: 12, y: 0, w: 1, h: 1 },
    { i: '56', x: 13, y: 0, w: 1, h: 2 },
    { i: '57', x: 0, y: 0, w: 1, h: 1 },
    { i: '58', x: 1, y: 1, w: 1, h: 1 },
    { i: '59', x: 2, y: 1, w: 4, h: 1 },
    // { i: '60', x: 3, y: 0, w: 1, h: 1 },
    // // { i: '61', x: 4, y: 0, w: 1, h: 1 },
    // // { i: '62', x: 5, y: 0, w: 1, h: 1 },
    { i: '63', x: 6, y: 0, w: 1, h: 1 },
    { i: '64', x: 7, y: 1, w: 2, h: 1 },
    // // { i: '65', x: 8, y: 0, w: 1, h: 1 },
    { i: '66', x: 9, y: 1, w: 2, h: 1 },
    // { i: '67', x: 10, y: 0, w: 1, h: 1 },
    { i: '68', x: 11, y: 0, w: 1, h: 1 },
    // // { i: '69', x: 12, y: 0, w: 1, h: 1 },
    // // { i: '70', x: 13, y: 0, w: 1, h: 1 }
]

const pink = [
    { i: '1', x: 0, y: 1, w: 1, h: 1 },
    { i: '2', x: 1, y: 2, w: 1, h: 2 },
    { i: '3', x: 2, y: 0, w: 1, h: 1 },
    { i: '4', x: 4, y: 0, w: 1, h: 1 },
    { i: '5', x: 3, y: 0, w: 1, h: 1 },
    { i: '6', x: 6, y: 0, w: 1, h: 2 },
    { i: '7', x: 7, y: 0, w: 2, h: 1 },
    // { i: '8', x: 7, y: 0, w: 1, h: 1 },
    { i: '9', x: 5, y: 0, w: 1, h: 1 },
    { i: '10', x: 9, y: 1, w: 1, h: 1 },
    { i: '11', x: 10, y: 1, w: 2, h: 1 },
    // { i: '12', x: 11, y: 0, w: 1, h: 1 },
    { i: '13', x: 12, y: 0, w: 1, h: 1 },
    { i: '14', x: 13, y: 1, w: 1, h: 1 },
    { i: '15', x: 0, y: 0, w: 1, h: 1 },
    { i: '16', x: 1, y: 0, w: 1, h: 1 },
    { i: '17', x: 2, y: 0, w: 1, h: 1 },
    { i: '18', x: 3, y: 0, w: 2, h: 1 },
    // // { i: '19', x: 4, y: 0, w: 1, h: 1 },
    // { i: '20', x: 5, y: 0, w: 1, h: 1 },
    { i: '21', x: 7, y: 0, w: 1, h: 1 },
    { i: '22', x: 8, y: 0, w: 1, h: 1 },
    { i: '23', x: 5, y: 0, w: 1, h: 1 },
    { i: '24', x: 9, y: 0, w: 1, h: 1 },
    { i: '25', x: 10, y: 0, w: 1, h: 1 },
    { i: '26', x: 11, y: 0, w: 1, h: 1 },
    { i: '27', x: 12, y: 0, w: 1, h: 1 },
    { i: '28', x: 13, y: 0, w: 1, h: 1 },
    { i: '29', x: 0, y: 1, w: 1, h: 1 },
    // { i: '30', x: 1, y: 0, w: 1, h: 1 },
    { i: '31', x: 2, y: 0, w: 1, h: 1 },
    { i: '32', x: 3, y: 0, w: 1, h: 1 },
    { i: '33', x: 4, y: 0, w: 1, h: 1 },
    { i: '34', x: 5, y: 0, w: 1, h: 1 },
    { i: '35', x: 6, y: 0, w: 1, h: 1 },
    { i: '36', x: 7, y: 0, w: 1, h: 1 },
    { i: '37', x: 8, y: 0, w: 1, h: 1 },
    { i: '38', x: 9, y: 1, w: 1, h: 1 },
    { i: '39', x: 10, y: 1, w: 1, h: 1 },
    { i: '40', x: 11, y: 1, w: 2, h: 1 },
    // { i: '41', x: 12, y: 0, w: 1, h: 1 },
    { i: '42', x: 13, y: 1, w: 1, h: 1 },
    { i: '43', x: 0, y: 1, w: 1, h: 1 },
    { i: '44', x: 3, y: 1, w: 1, h: 1 },
    { i: '45', x: 2, y: 0, w: 1, h: 1 },
    // { i: '46', x: 1, y: 0, w: 1, h: 1 },
    { i: '47', x: 5, y: 0, w: 1, h: 1 },
    { i: '48', x: 4, y: 0, w: 1, h: 1 },
    { i: '49', x: 6, y: 1, w: 1, h: 1 },
    { i: '50', x: 7, y: 1, w: 4, h: 1 },
    // // { i: '51', x: 8, y: 0, w: 1, h: 1 },
    // { i: '52', x: 9, y: 1, w: 1, h: 1 },
    // // { i: '53', x: 10, y: 0, w: 1, h: 1 },
    { i: '54', x: 11, y: 1, w: 1, h: 1 },
    { i: '55', x: 12, y: 1, w: 1, h: 1 },
    { i: '56', x: 13, y: 1, w: 1, h: 2 },
    { i: '57', x: 0, y: 1, w: 1, h: 1 },
    { i: '58', x: 1, y: 1, w: 1, h: 1 },
    { i: '59', x: 2, y: 1, w: 4, h: 1 },
    // { i: '60', x: 3, y: 0, w: 1, h: 1 },
    // // { i: '61', x: 4, y: 0, w: 1, h: 1 },
    // // { i: '62', x: 5, y: 0, w: 1, h: 1 },
    { i: '63', x: 6, y: 0, w: 1, h: 1 },
    { i: '64', x: 7, y: 1, w: 2, h: 1 },
    // // { i: '65', x: 8, y: 0, w: 1, h: 1 },
    { i: '66', x: 9, y: 1, w: 2, h: 1 },
    // { i: '67', x: 10, y: 0, w: 1, h: 1 },
    { i: '68', x: 11, y: 1, w: 1, h: 1 },
    // // { i: '69', x: 12, y: 0, w: 1, h: 1 },
    // // { i: '70', x: 13, y: 0, w: 1, h: 1 }
]

const green = [
    { i: '1', x: 0, y: 1, w: 1, h: 1 },
    { i: '2', x: 1, y: 1, w: 1, h: 2 },
    { i: '3', x: 2, y: 0, w: 1, h: 2 },
    { i: '4', x: 4, y: 0, w: 1, h: 1 },
    { i: '5', x: 3, y: 0, w: 1, h: 1 },
    { i: '6', x: 5, y: 0, w: 1, h: 2 },
    { i: '7', x: 6, y: 0, w: 2, h: 1 },
    // { i: '8', x: 7, y: 0, w: 1, h: 1 },
    { i: '9', x: 8, y: 0, w: 1, h: 1 },
    { i: '10', x: 9, y: 1, w: 1, h: 1 },
    { i: '11', x: 10, y: 0, w: 2, h: 1 },
    // { i: '12', x: 11, y: 0, w: 1, h: 1 },
    { i: '13', x: 12, y: 0, w: 1, h: 1 },
    { i: '14', x: 13, y: 1, w: 1, h: 1 },
    { i: '15', x: 0, y: 0, w: 1, h: 1 },
    { i: '16', x: 1, y: 0, w: 1, h: 1 },
    // { i: '17', x: 2, y: 0, w: 1, h: 1 },
    { i: '18', x: 3, y: 0, w: 2, h: 1 },
    // // { i: '19', x: 4, y: 0, w: 1, h: 1 },
    // { i: '20', x: 5, y: 0, w: 1, h: 1 },
    { i: '21', x: 7, y: 0, w: 1, h: 1 },
    { i: '22', x: 8, y: 0, w: 1, h: 1 },
    { i: '23', x: 6, y: 0, w: 1, h: 1 },
    { i: '24', x: 9, y: 0, w: 1, h: 1 },
    { i: '25', x: 10, y: 0, w: 1, h: 1 },
    { i: '26', x: 11, y: 0, w: 1, h: 1 },
    { i: '27', x: 12, y: 0, w: 1, h: 1 },
    { i: '28', x: 13, y: 1, w: 1, h: 1 },
    { i: '29', x: 0, y: 1, w: 1, h: 1 },
    // { i: '30', x: 1, y: 0, w: 1, h: 1 },
    { i: '31', x: 2, y: 0, w: 1, h: 1 },
    { i: '32', x: 3, y: 0, w: 1, h: 1 },
    { i: '33', x: 4, y: 0, w: 1, h: 1 },
    { i: '34', x: 5, y: 0, w: 1, h: 1 },
    { i: '35', x: 6, y: 0, w: 1, h: 1 },
    { i: '36', x: 7, y: 0, w: 1, h: 1 },
    { i: '37', x: 8, y: 0, w: 1, h: 1 },
    { i: '38', x: 9, y: 1, w: 1, h: 1 },
    { i: '39', x: 10, y: 1, w: 1, h: 1 },
    { i: '40', x: 11, y: 1, w: 2, h: 1 },
    // { i: '41', x: 12, y: 0, w: 1, h: 1 },
    { i: '42', x: 13, y: 2, w: 1, h: 1 },
    { i: '43', x: 0, y: 1, w: 1, h: 1 },
    { i: '44', x: 2, y: 1, w: 1, h: 1 },
    // { i: '45', x: 2, y: 0, w: 1, h: 1 },
    { i: '46', x: 3, y: 0, w: 1, h: 1 },
    { i: '47', x: 5, y: 0, w: 1, h: 1 },
    { i: '48', x: 4, y: 0, w: 1, h: 1 },
    { i: '49', x: 6, y: 0, w: 1, h: 1 },
    { i: '50', x: 7, y: 1, w: 4, h: 1 },
    // // { i: '51', x: 8, y: 0, w: 1, h: 1 },
    // { i: '52', x: 9, y: 1, w: 1, h: 1 },
    // // { i: '53', x: 10, y: 0, w: 1, h: 1 },
    { i: '54', x: 11, y: 1, w: 1, h: 1 },
    { i: '55', x: 12, y: 1, w: 1, h: 1 },
    { i: '56', x: 13, y: 1, w: 1, h: 2 },
    { i: '57', x: 0, y: 1, w: 1, h: 1 },
    { i: '58', x: 1, y: 1, w: 1, h: 1 },
    { i: '59', x: 2, y: 1, w: 4, h: 1 },
    // { i: '60', x: 3, y: 0, w: 1, h: 1 },
    // // { i: '61', x: 4, y: 0, w: 1, h: 1 },
    // // { i: '62', x: 5, y: 0, w: 1, h: 1 },
    { i: '63', x: 6, y: 0, w: 1, h: 1 },
    { i: '64', x: 7, y: 1, w: 2, h: 1 },
    // // { i: '65', x: 8, y: 0, w: 1, h: 1 },
    { i: '66', x: 9, y: 1, w: 2, h: 1 },
    // { i: '67', x: 10, y: 0, w: 1, h: 1 },
    { i: '68', x: 11, y: 1, w: 1, h: 1 },
    // // { i: '69', x: 12, y: 0, w: 1, h: 1 },
    // // { i: '70', x: 13, y: 0, w: 1, h: 1 }
]

const peach = [
    { i: '1', x: 0, y: 1, w: 1, h: 1 },
    { i: '2', x: 1, y: 1, w: 1, h: 2 },
    { i: '3', x: 2, y: 0, w: 1, h: 1 },
    { i: '4', x: 3, y: 0, w: 1, h: 1 },
    { i: '5', x: 4, y: 0, w: 1, h: 1 },
    { i: '6', x: 5, y: 0, w: 1, h: 1 },
    { i: '7', x: 6, y: 1, w: 2, h: 1 },
    // { i: '8', x: 7, y: 0, w: 1, h: 1 },
    { i: '9', x: 8, y: 0, w: 1, h: 1 },
    { i: '10', x: 9, y: 1, w: 1, h: 1 },
    { i: '11', x: 10, y: 0, w: 2, h: 1 },
    // { i: '12', x: 11, y: 0, w: 1, h: 1 },
    { i: '13', x: 12, y: 0, w: 1, h: 1 },
    { i: '14', x: 13, y: 1, w: 1, h: 1 },
    { i: '15', x: 0, y: 0, w: 1, h: 1 },
    { i: '16', x: 1, y: 0, w: 1, h: 1 },
    { i: '17', x: 2, y: 0, w: 1, h: 1 },
    { i: '18', x: 3, y: 0, w: 2, h: 1 },
    // // { i: '19', x: 4, y: 0, w: 1, h: 1 },
    { i: '20', x: 5, y: 0, w: 1, h: 1 },
    { i: '21', x: 7, y: 0, w: 1, h: 1 },
    { i: '22', x: 8, y: 0, w: 1, h: 1 },
    { i: '23', x: 6, y: 0, w: 1, h: 1 },
    { i: '24', x: 9, y: 0, w: 1, h: 1 },
    { i: '25', x: 12, y: 0, w: 1, h: 1 },
    { i: '26', x: 11, y: 0, w: 1, h: 1 },
    { i: '27', x: 10, y: 0, w: 1, h: 1 },
    { i: '28', x: 13, y: 1, w: 1, h: 1 },
    { i: '29', x: 0, y: 1, w: 1, h: 1 },
    // { i: '30', x: 1, y: 0, w: 1, h: 1 },
    { i: '31', x: 2, y: 0, w: 1, h: 1 },
    { i: '32', x: 3, y: 0, w: 1, h: 1 },
    { i: '33', x: 4, y: 0, w: 1, h: 1 },
    { i: '34', x: 5, y: 0, w: 1, h: 1 },
    { i: '35', x: 6, y: 1, w: 1, h: 1 },
    { i: '36', x: 7, y: 1, w: 1, h: 1 },
    { i: '37', x: 8, y: 0, w: 1, h: 1 },
    { i: '38', x: 9, y: 1, w: 1, h: 1 },
    { i: '39', x: 10, y: 1, w: 1, h: 1 },
    { i: '40', x: 11, y: 2, w: 2, h: 1 },
    // { i: '41', x: 12, y: 0, w: 1, h: 1 },
    { i: '42', x: 13, y: 2, w: 1, h: 1 },
    { i: '43', x: 0, y: 2, w: 1, h: 1 },
    { i: '44', x: 1, y: 1, w: 1, h: 1 },
    // { i: '45', x: 2, y: 0, w: 1, h: 1 },
    { i: '46', x: 3, y: 0, w: 1, h: 1 },
    { i: '47', x: 6, y: 1, w: 1, h: 1 },
    { i: '48', x: 4, y: 0, w: 1, h: 1 },
    { i: '49', x: 5, y: 0, w: 1, h: 1 },
    { i: '50', x: 7, y: 1, w: 4, h: 1 },
    // // { i: '51', x: 8, y: 0, w: 1, h: 1 },
    // { i: '52', x: 9, y: 1, w: 1, h: 1 },
    // // { i: '53', x: 10, y: 0, w: 1, h: 1 },
    { i: '54', x: 11, y: 1, w: 1, h: 1 },
    { i: '55', x: 12, y: 1, w: 1, h: 1 },
    { i: '56', x: 13, y: 2, w: 1, h: 2 },
    { i: '57', x: 0, y: 1, w: 1, h: 1 },
    { i: '58', x: 1, y: 1, w: 1, h: 1 },
    { i: '59', x: 2, y: 1, w: 4, h: 1 },
    // { i: '60', x: 3, y: 0, w: 1, h: 1 },
    // // { i: '61', x: 4, y: 0, w: 1, h: 1 },
    // // { i: '62', x: 5, y: 0, w: 1, h: 1 },
    { i: '63', x: 6, y: 1, w: 1, h: 1 },
    { i: '64', x: 7, y: 1, w: 2, h: 1 },
    // // { i: '65', x: 8, y: 0, w: 1, h: 1 },
    { i: '66', x: 9, y: 1, w: 2, h: 1 },
    // { i: '67', x: 10, y: 0, w: 1, h: 1 },
    { i: '68', x: 11, y: 2, w: 1, h: 1 },
    // // { i: '69', x: 12, y: 0, w: 1, h: 1 },
    // // { i: '70', x: 13, y: 0, w: 1, h: 1 }
]

const purple = [
    // { i: '1', x: 0, y: 1, w: 1, h: 1 },
    { i: '2', x: 1, y: 2, w: 1, h: 2 },
    { i: '3', x: 2, y: 0, w: 1, h: 2 },
    { i: '4', x: 4, y: 0, w: 1, h: 1 },
    { i: '5', x: 3, y: 0, w: 1, h: 1 },
    { i: '6', x: 5, y: 0, w: 1, h: 2 },
    { i: '7', x: 6, y: 0, w: 2, h: 1 },
    // { i: '8', x: 7, y: 0, w: 1, h: 1 },
    { i: '9', x: 8, y: 0, w: 1, h: 1 },
    // { i: '10', x: 9, y: 1, w: 1, h: 1 },
    { i: '11', x: 10, y: 0, w: 2, h: 1 },
    // { i: '12', x: 11, y: 0, w: 1, h: 1 },
    { i: '13', x: 12, y: 0, w: 1, h: 1 },
    { i: '14', x: 13, y: 1, w: 1, h: 1 },
    { i: '15', x: 0, y: 0, w: 1, h: 2 },
    { i: '16', x: 1, y: 0, w: 1, h: 1 },
    // { i: '17', x: 2, y: 0, w: 1, h: 1 },
    { i: '18', x: 3, y: 0, w: 2, h: 1 },
    // // { i: '19', x: 4, y: 0, w: 1, h: 1 },
    // { i: '20', x: 5, y: 0, w: 1, h: 1 },
    { i: '21', x: 7, y: 0, w: 1, h: 1 },
    { i: '22', x: 8, y: 0, w: 1, h: 1 },
    { i: '23', x: 6, y: 0, w: 1, h: 1 },
    { i: '24', x: 9, y: 0, w: 1, h: 2 },
    { i: '25', x: 10, y: 0, w: 1, h: 1 },
    { i: '26', x: 12, y: 0, w: 1, h: 1 },
    { i: '27', x: 11, y: 0, w: 1, h: 1 },
    { i: '28', x: 13, y: 2, w: 1, h: 1 },
    { i: '29', x: 0, y: 1, w: 1, h: 1 },
    { i: '30', x: 1, y: 0, w: 1, h: 1 },
    { i: '31', x: 2, y: 0, w: 1, h: 1 },
    { i: '32', x: 3, y: 0, w: 1, h: 1 },
    { i: '33', x: 4, y: 0, w: 1, h: 1 },
    { i: '34', x: 5, y: 0, w: 1, h: 1 },
    { i: '35', x: 6, y: 0, w: 1, h: 1 },
    { i: '36', x: 7, y: 0, w: 1, h: 1 },
    { i: '37', x: 8, y: 0, w: 1, h: 1 },
    { i: '38', x: 9, y: 1, w: 1, h: 1 },
    { i: '39', x: 10, y: 1, w: 1, h: 1 },
    { i: '40', x: 11, y: 1, w: 2, h: 1 },
    // { i: '41', x: 12, y: 0, w: 1, h: 1 },
    { i: '42', x: 13, y: 1, w: 1, h: 1 },
    { i: '43', x: 0, y: 1, w: 1, h: 1 },
    { i: '44', x: 2, y: 1, w: 1, h: 1 },
    // { i: '45', x: 2, y: 0, w: 1, h: 1 },
    { i: '46', x: 3, y: 0, w: 1, h: 1 },
    { i: '47', x: 5, y: 0, w: 1, h: 1 },
    { i: '48', x: 4, y: 0, w: 1, h: 1 },
    { i: '49', x: 6, y: 1, w: 1, h: 1 },
    { i: '50', x: 7, y: 1, w: 4, h: 1 },
    // // { i: '51', x: 8, y: 0, w: 1, h: 1 },
    // { i: '52', x: 9, y: 1, w: 1, h: 1 },
    // // { i: '53', x: 10, y: 0, w: 1, h: 1 },
    { i: '54', x: 11, y: 1, w: 1, h: 1 },
    { i: '55', x: 12, y: 1, w: 1, h: 1 },
    { i: '56', x: 13, y: 2, w: 1, h: 2 },
    { i: '57', x: 0, y: 1, w: 1, h: 1 },
    { i: '58', x: 1, y: 1, w: 1, h: 1 },
    { i: '59', x: 2, y: 1, w: 4, h: 1 },
    // { i: '60', x: 3, y: 0, w: 1, h: 1 },
    // // { i: '61', x: 4, y: 0, w: 1, h: 1 },
    // // { i: '62', x: 5, y: 0, w: 1, h: 1 },
    { i: '63', x: 6, y: 0, w: 1, h: 1 },
    { i: '64', x: 7, y: 1, w: 2, h: 1 },
    // // { i: '65', x: 8, y: 0, w: 1, h: 1 },
    { i: '66', x: 9, y: 1, w: 2, h: 1 },
    // { i: '67', x: 10, y: 0, w: 1, h: 1 },
    { i: '68', x: 11, y: 1, w: 1, h: 1 },
    // // { i: '69', x: 12, y: 0, w: 1, h: 1 },
    // // { i: '70', x: 13, y: 0, w: 1, h: 1 }
]

const orange = [
    { i: '1', x: 0, y: 1, w: 1, h: 1 },
    { i: '2', x: 1, y: 0, w: 1, h: 2 },
    { i: '3', x: 2, y: 0, w: 1, h: 2 },
    { i: '4', x: 4, y: 0, w: 1, h: 1 },
    { i: '5', x: 3, y: 0, w: 1, h: 1 },
    { i: '6', x: 5, y: 0, w: 1, h: 1 },
    { i: '7', x: 6, y: 0, w: 2, h: 1 },
    // { i: '8', x: 7, y: 0, w: 1, h: 1 },
    { i: '9', x: 8, y: 0, w: 1, h: 1 },
    { i: '10', x: 9, y: 1, w: 1, h: 1 },
    { i: '11', x: 10, y: 0, w: 2, h: 1 },
    // { i: '12', x: 11, y: 0, w: 1, h: 1 },
    { i: '13', x: 13, y: 0, w: 1, h: 1 },
    { i: '14', x: 12, y: 0, w: 1, h: 1 },
    { i: '15', x: 0, y: 0, w: 1, h: 1 },
    { i: '16', x: 1, y: 0, w: 1, h: 1 },
    // { i: '17', x: 2, y: 0, w: 1, h: 1 },
    { i: '18', x: 3, y: 0, w: 2, h: 1 },
    // // { i: '19', x: 4, y: 0, w: 1, h: 1 },
    { i: '20', x: 5, y: 0, w: 1, h: 1 },
    { i: '21', x: 7, y: 0, w: 1, h: 1 },
    { i: '22', x: 8, y: 0, w: 1, h: 1 },
    { i: '23', x: 6, y: 0, w: 1, h: 1 },
    { i: '24', x: 9, y: 0, w: 1, h: 1 },
    { i: '25', x: 13, y: 1, w: 1, h: 1 },
    { i: '26', x: 11, y: 0, w: 1, h: 1 },
    { i: '27', x: 12, y: 1, w: 1, h: 1 },
    { i: '28', x: 10, y: 0, w: 1, h: 1 },
    { i: '29', x: 0, y: 2, w: 1, h: 1 },
    // { i: '30', x: 1, y: 0, w: 1, h: 1 },
    { i: '31', x: 2, y: 0, w: 1, h: 1 },
    { i: '32', x: 3, y: 0, w: 1, h: 1 },
    { i: '33', x: 4, y: 0, w: 1, h: 1 },
    { i: '34', x: 5, y: 0, w: 1, h: 1 },
    { i: '35', x: 6, y: 0, w: 1, h: 1 },
    { i: '36', x: 7, y: 0, w: 1, h: 1 },
    { i: '37', x: 8, y: 0, w: 1, h: 1 },
    { i: '38', x: 9, y: 1, w: 1, h: 1 },
    { i: '39', x: 10, y: 1, w: 1, h: 1 },
    { i: '40', x: 11, y: 1, w: 2, h: 1 },
    // { i: '41', x: 12, y: 0, w: 1, h: 1 },
    { i: '42', x: 13, y: 2, w: 1, h: 1 },
    { i: '43', x: 0, y: 1, w: 1, h: 1 },
    { i: '44', x: 2, y: 1, w: 1, h: 1 },
    // { i: '45', x: 2, y: 0, w: 1, h: 1 },
    { i: '46', x: 3, y: 0, w: 1, h: 1 },
    { i: '47', x: 6, y: 0, w: 1, h: 1 },
    { i: '48', x: 4, y: 0, w: 1, h: 1 },
    { i: '49', x: 5, y: 0, w: 1, h: 1 },
    { i: '50', x: 7, y: 1, w: 4, h: 1 },
    // // { i: '51', x: 8, y: 0, w: 1, h: 1 },
    // { i: '52', x: 9, y: 1, w: 1, h: 1 },
    // // { i: '53', x: 10, y: 0, w: 1, h: 1 },
    { i: '54', x: 11, y: 0, w: 1, h: 1 },
    { i: '55', x: 12, y: 0, w: 1, h: 1 },
    { i: '56', x: 13, y: 0, w: 1, h: 2 },
    { i: '57', x: 0, y: 1, w: 1, h: 1 },
    { i: '58', x: 1, y: 1, w: 1, h: 1 },
    { i: '59', x: 2, y: 1, w: 4, h: 1 },
    // { i: '60', x: 3, y: 0, w: 1, h: 1 },
    // // { i: '61', x: 4, y: 0, w: 1, h: 1 },
    // // { i: '62', x: 5, y: 0, w: 1, h: 1 },
    { i: '63', x: 6, y: 0, w: 1, h: 1 },
    { i: '64', x: 7, y: 1, w: 2, h: 1 },
    // // { i: '65', x: 8, y: 0, w: 1, h: 1 },
    { i: '66', x: 9, y: 1, w: 2, h: 1 },
    // { i: '67', x: 10, y: 0, w: 1, h: 1 },
    { i: '68', x: 11, y: 1, w: 1, h: 1 },
    // // { i: '69', x: 12, y: 0, w: 1, h: 1 },
    // // { i: '70', x: 13, y: 0, w: 1, h: 1 }
]

const scaleGrid = (grid, scale) => {
    return grid.map((item) => {
        return { ...item, x: item.x * scale, w: item.w * scale }
    })
}

export const layouts = {
    orange: {
        lg: scaleGrid(orange, 6),
        md: scaleGrid(orange, 3),
        sm: scaleGrid(orange, 4),
        xs: scaleGrid(orange, 2),
    },
    yellow: {
        lg: scaleGrid(yellow, 6),
        md: scaleGrid(yellow, 3),
        sm: scaleGrid(yellow, 4),
        xs: scaleGrid(yellow, 2),

    },
    pink: {
        lg: scaleGrid(pink, 6),
        md: scaleGrid(pink, 3),
        sm: scaleGrid(pink, 4),
        xs: scaleGrid(pink, 2),
    },
    light_green: {
        lg: scaleGrid(green, 6),
        md: scaleGrid(green, 3),
        sm: scaleGrid(green, 4),
        xs: scaleGrid(green, 2),
    },
    peach: {
        lg: scaleGrid(peach, 6),
        md: scaleGrid(peach, 3),
        sm: scaleGrid(peach, 4),
        xs: scaleGrid(peach, 2),
    },
    green: {
        lg: scaleGrid(green, 6),
        md: scaleGrid(green, 3),
        sm: scaleGrid(green, 4),
        xs: scaleGrid(green, 2),
    },
    purple: {
        lg: scaleGrid(purple, 6),
        md: scaleGrid(purple, 3),
        sm: scaleGrid(purple, 4),
        xs: scaleGrid(purple, 2),
    },
    cyan: {
        lg: scaleGrid(green, 6),
        md: scaleGrid(green, 3),
        sm: scaleGrid(green, 4),
        xs: scaleGrid(green, 2),
    },
}
